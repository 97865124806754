import { useMemo, useEffect, useState, useContext } from 'react';

import moment from 'moment';

import { Link } from 'react-router-dom';

import { useDropzone } from 'react-dropzone';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { getMessage } from 'utils/customMessages';
import { ThemeContext } from 'styled-components';

import WebSocketInstance from 'services/websocket';

import { Picker } from 'emoji-mart';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperPlane,
  faSmile,
  faCommentAlt,
} from '@fortawesome/free-regular-svg-icons';
import {
  faPaperclip,
  faSpinner,
  faChevronDown,
  faChevronUp,
  faFilePdf,
  faFile,
  faFlag,
  faCommentAlt as fasCommentAlt,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { Button } from '@cloudez/cloudez-design-system';

import { StyledDropzone } from 'components/NewComponents/Dropzone';
import { Breadcrumbs, Breadcrumb } from 'components/Common';
import { CustomTextArea } from 'components/NewComponents';

import { useAuth, useDocTitle, useNotifications } from 'hooks';
import cloudez from 'assets/img/svg/cloud_cloudez.svg';
import {
  Header,
  Title,
  TicketWrapper,
  TicketHeader,
  UserInfo,
  TicketTitle,
  Updates,
  Files,
  File,
  TicketFooter,
  Messages,
  Icons,
  RedAlert,
  Typing,
} from './styles';
import RatingModal from './RatingModal';
import IssueModal from './IssueModal';
import HelpModal from './HelpModal';
import StaffInfo from './StaffInfo';
import Message from './Message';

import { TicketDetailContext, TicketDetailProvider } from './_context/state';

const TicketDetail = ({ match }) => {
  const { acceptedFiles, getInputProps, getRootProps } = useDropzone();

  const theme = useContext(ThemeContext);
  const {
    ticket,
    getTicket,
    updateTicket,
    getUser,
    user: _user,
    getClouds,
    clouds,
    getTarget,
    target,
    getMessages,
    messages,
    files,
    addMessage,
    addWSMessage,
    setRedAlert,
  } = useContext(TicketDetailContext);

  const { company, user } = useAuth();

  const { notifications, patchNotification } = useNotifications();

  const [showEmokiPicker, setShowEmokiPicker] = useState(false);

  const [showIssueModal, setShowIssueModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [ratingModal, setRatingModal] = useState(false);
  const [redLoading, setRedLoading] = useState(false);
  const [typingUsers, setTypingUsers] = useState([]);
  const [showFiles, setShowFiles] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [hasFile, setHasFile] = useState(false);
  const [message, setMessage] = useState('');
  const [now, setNow] = useState(false);

  useEffect(() => {
    const { id } = match.params;

    const notification = notifications?.filter(
      n => n && n.type === 'ticket' && n.resource_id === Number(id),
    );

    if (notification?.length > 0) {
      if (notification[0].status === 0)
        patchNotification({ id: notification[0].id, data: { status: 1 } });
    }
    // eslint-disable-next-line
  }, [notifications]);

  useEffect(() => {
    if (showFiles) {
      document.addEventListener('keydown', e => {
        if (e.keyCode === 27) {
          setShowFiles(false);
        }
      });
    }
    // eslint-disable-next-line
  }, [showFiles]);

  const cb = ({ data }) => {
    const {
      message: { type, data: _data },
    } = JSON.parse(data);

    switch (type) {
      case 'typing': {
        if (_data.id !== user.id) {
          setTypingUsers(_users => {
            if (_users.find(u => _data.id === u.id)) {
              return _users.map(u => (u.id === _data.id ? _data : u));
            }
            return [..._users, _data];
          });
        }

        break;
      }
      case 'message': {
        addWSMessage({ ..._data, isNew: true });
        break;
      }
      default: {
        break;
      }
    }
  };

  const messagesCloudezHelp = {
    message1:
      'O seu atendimento foi escalado para nossos especialistas. Em breve eles entrarão em contato.',
    message2: 'O seu atendimento com os nossos especialistas foi encerrado.',
  };

  useEffect(() => {
    const { id } = match.params;

    getTicket(id);
    getMessages(id);
    WebSocketInstance.connectTicket(user.uuid, id);
    WebSocketInstance.socketRef.onmessage = cb;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { id } = match.params;
    if (refresh) {
      getTicket(id);
      getMessages(id);

      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    if (ticket && user.is_staff) {
      if (!_user) {
        getUser(ticket.owner);
      }

      if (!clouds) {
        getClouds(ticket.owner_email);
      }

      if (!target && ticket.target_type !== '' && ticket.target_id) {
        getTarget(ticket.target_type, ticket.target_id);
      }
    }

    // eslint-disable-next-line
  }, [ticket]);

  const handleUserKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      sendMessage(e.target.value);
      e.preventDefault();
    }
  };

  const sendMessage = async e => {
    if (!loading) {
      try {
        let msg = message;
        let canSend = false;

        if (typeof e === 'string') {
          msg = e;
          canSend = true;
        }

        if (e.type === 'keydown') {
          if (e.ctrlKey && e.keyCode === 13) {
            setLoading(true);
            msg = e.target.value;
            canSend = true;
          } else {
            return;
          }
        }

        if (e.type === 'keydown' && !canSend) {
          return;
        }

        const data = new FormData();

        data.append('ticket', ticket.id);
        data.append('text', msg);
        if (
          e === messagesCloudezHelp.message1 ||
          e === messagesCloudezHelp.message2
        ) {
          data.append('author_external', 'system');
        } else {
          data.append('author', user.id);
        }

        if (acceptedFiles?.length > 0) data.append('file', acceptedFiles[0]);

        await addMessage(data);

        acceptedFiles.splice(acceptedFiles.indexOf(acceptedFiles[0]), 1);

        WebSocketInstance.send(
          JSON.stringify({
            type: 'typing',
            data: {
              name: user.full_name,
              id: user.id,
              typing: false,
            },
          }),
        );
        setHasFile(false);
        setMessage('');
        setLoading(false);
      } catch (e) {
        toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
        setLoading(false);
      }
    }
  };

  const closeIn7Days = async (issue, whoHelped, now = true) => {
    if (now) {
      await updateTicket(null, null, ticket.id, {
        ...ticket,
        status: ticket.status !== 2 ? 2 : 3,
        issue: issue || ticket.issue,
        who_helped: whoHelped || ticket.who_helped,
      });
    } else {
      const date = moment().add(7, 'd').format('YYYY-MM-DDTHH:mm:ss');

      await updateTicket(null, null, ticket.id, {
        ...ticket,
        expiration_date: date,
        issue: issue || ticket.issue,
        who_helped: whoHelped || ticket.who_helped,
      });
    }
  };

  const closeClient = async (rating, ratingComment) => {
    await updateTicket(null, null, ticket.id, {
      ...ticket,
      status: 2,
      rating,
      rating_comment: ratingComment,
    });
  };

  const cancelExpiration = async () => {
    const date = null;

    await updateTicket('expiration_date', date, ticket.id);
  };

  const checkForShortcut = shortcut => {
    const regex = new RegExp(/\/(.*?)\//gm);

    if (user.is_staff) {
      if (shortcut && regex.test(shortcut)) {
        if (getMessage(shortcut.split(regex)[1])) {
          setMessage(getMessage(shortcut.split(regex)[1]).value);
        }
      }
    }
  };

  let createdAt;
  let updatedAt;

  if (ticket) {
    createdAt = moment(ticket.created_at).locale('pt-BR').fromNow();
    updatedAt = moment(ticket.updated_at).locale('pt-BR').fromNow();
  }

  const ticketOwner = useMemo(() => {
    if (ticket) {
      const owner = ticket.owner_email;
      if (ticket.is_cloudez || !user.is_staff) {
        if (owner?.includes('configr.com')) {
          return owner.replace('configr.com', 'cloudez.io');
        }
        if (owner?.includes('confi.gr')) {
          return owner.replace('confi.gr', 'cloudez.io');
        }
        return owner;
      }
      return owner;
    }
  }, [ticket, user.is_staff]);

  const ticketLastMessageAuthor = useMemo(() => {
    if (ticket) {
      const author = ticket.last_message_author;
      if (ticket.is_cloudez || !user.is_staff) {
        if (author?.includes('configr.com')) {
          return author.replace('configr.com', 'cloudez.io');
        }
        if (author?.includes('confi.gr')) {
          return author.replace('confi.gr', 'cloudez.io');
        }
        return author;
      }
      return author;
    }
  }, [ticket, user.is_staff]);

  useDocTitle(
    ticket ? `Ticket #${ticket?.id} - Suporte — Cloudez` : 'Suporte — Cloudez',
  );

  return (
    ticket &&
    messages &&
    user &&
    company && (
      <>
        {user.is_staff && showIssueModal && (
          <IssueModal
            show={showIssueModal}
            setShow={setShowIssueModal}
            action={closeIn7Days}
            now={now}
            ticket={ticket}
          />
        )}

        {!user.is_staff && ratingModal && (
          <RatingModal
            show={ratingModal}
            setShow={setRatingModal}
            ticket={ticket}
            action={closeClient}
          />
        )}

        {showHelpModal && (
          <HelpModal
            sendMessage={sendMessage}
            show={showHelpModal}
            setShow={setShowHelpModal}
            getTicket={getTicket}
            ticket={ticket}
          />
        )}

        <Header>
          <Row>
            <Col md="8">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Title>
                  <Breadcrumbs>
                    <Breadcrumb>
                      <Link to="/dashboard">Home</Link>
                    </Breadcrumb>
                    <FontAwesomeIcon icon={faChevronRight} />
                    <Breadcrumb>
                      <Link to="/suporte">Suporte</Link>
                    </Breadcrumb>
                  </Breadcrumbs>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  />
                  Ticket # {ticket && ticket.id}
                </Title>
              </div>
            </Col>
            <Col
              md="4"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {ticket.can_ask_help && user.is_company_owner && (
                <Button
                  style={{ marginRight: '16px' }}
                  onClick={() => setShowHelpModal(true)}
                >
                  {!ticket.asked_help ? 'Ajuda Cloudez' : 'Encerrar Ajuda'}
                </Button>
              )}

              <Button
                error
                outline
                onClick={() => {
                  if (user.is_staff) {
                    if (ticket.status !== 2) {
                      setNow(true);
                      setShowIssueModal(true);
                    } else closeIn7Days(null, null, true);
                  } else if (ticket.status !== 2) {
                    setNow(true);
                    setRatingModal(true);
                  } else closeIn7Days(null, null, true);
                }}
              >
                {ticket.status !== 2 ? 'Fechar ticket' : 'Reabrir ticket'}
              </Button>
            </Col>
          </Row>
        </Header>

        <TicketWrapper>
          <TicketHeader>
            <UserInfo>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TicketTitle>{ticket.summary}</TicketTitle>
              </div>
              {user.is_staff && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {ticket?.is_cloudez && (
                    <img src={cloudez} width="50px" alt="cloudez" />
                  )}
                  {user.is_staff && ticket.status !== 2 ? (
                    ticket.expiration_date ? (
                      <div>
                        <p
                          style={{
                            marginLeft: '14px',
                            fontSize: '10px',
                            color: theme.interface5,
                            cursor: 'pointer',
                          }}
                        >
                          {moment(ticket.expiration_date)
                            .locale('pt-BR')
                            .fromNow(true)}
                        </p>
                        <p
                          style={{
                            marginLeft: '10px',
                            fontSize: '10px',
                            color: theme.brand_primary_color,
                            cursor: 'pointer',
                          }}
                          onClick={cancelExpiration}
                        >
                          (Cancelar)
                        </p>
                      </div>
                    ) : (
                      <p
                        style={{
                          marginLeft: '14px',
                          fontSize: '10px',
                          color: theme.brand_primary_color,
                          cursor: 'pointer',
                        }}
                        onClick={() => setShowIssueModal(true)}
                      >
                        Fechar em 7 dias
                      </p>
                    )
                  ) : null}
                </div>
              )}
            </UserInfo>

            <Updates>
              <p>
                Área <br />{' '}
                <span>
                  {ticket.team === 1
                    ? 'Suporte'
                    : ticket.team === 2
                    ? 'Financeiro'
                    : ticket.team === 3
                    ? 'Comercial'
                    : ticket.team === 4
                    ? 'Migração'
                    : ticket.team === 5
                    ? 'Spam'
                    : ticket.team === 6
                    ? 'Alertas'
                    : ticket.team === 7
                    ? 'Painel'
                    : ticket.team === 8 || ticket.team === 0
                    ? 'Outros'
                    : ''}
                </span>
                <br />
              </p>
              <p
                style={{
                  width: '100px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                Criado em <br />{' '}
                <span>
                  {createdAt} <br />
                  <span>{ticketOwner}</span>
                </span>
              </p>
              <p
                style={{
                  width: '100px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                Atualizado em <br />{' '}
                <span>
                  {updatedAt} <br />
                  <span>{ticketLastMessageAuthor}</span>
                </span>
              </p>
            </Updates>
            {user.is_staff && (
              <RedAlert>
                <FontAwesomeIcon
                  icon={redLoading ? faSpinner : faFlag}
                  spin={redLoading}
                  style={{
                    cursor: 'pointer',
                  }}
                  color={ticket?.is_red_alert ? theme.error : theme.interface4}
                  onClick={async () => {
                    setRedLoading(true);
                    await setRedAlert(ticket?.id);
                    setRedLoading(false);
                  }}
                />
              </RedAlert>
            )}
            {!!files.length && (
              <Files hasFiles={!!files.length} files={files}>
                <FontAwesomeIcon icon={faPaperclip} />
                <span
                  style={{
                    marginLeft: '6px',
                  }}
                >
                  Anexos
                </span>
                {files.map((f, i) => {
                  const hasImage =
                    f.includes('.png') ||
                    f.includes('.jpg') ||
                    f.includes('.jpe') ||
                    f.includes('.jpeg');
                  return (
                    <File key={i}>
                      <span
                        onClick={() => {
                          window.open(f, '_blank');
                          setShowFiles(false);
                        }}
                      >
                        {hasImage ? (
                          <img src={f} alt={f} />
                        ) : (
                          <FontAwesomeIcon
                            icon={f.includes('.pdf') ? faFilePdf : faFile}
                          />
                        )}
                      </span>
                    </File>
                  );
                })}
              </Files>
            )}
          </TicketHeader>

          <Messages>
            {messages.length >= 4 && (
              <FontAwesomeIcon
                icon={faChevronDown}
                onClick={() =>
                  window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: 'smooth',
                  })
                }
              />
            )}
            {messages.map(msg => {
              return (
                <Message
                  company={company}
                  ticket={ticket}
                  key={msg.id}
                  msg={msg}
                  user={user}
                />
              );
            })}
            {messages && messages.length >= 4 && (
              <FontAwesomeIcon
                icon={faChevronUp}
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              />
            )}
            {typingUsers?.find(u => u.typing === true) && (
              <Typing>
                {typingUsers.map(
                  (u, i) =>
                    u.id !== user.id &&
                    u.typing && (
                      <p>
                        {u.name}
                        {typingUsers.length > 1 && i + 1 !== typingUsers.length
                          ? ', '
                          : ' '}
                      </p>
                    ),
                )}
              </Typing>
            )}
          </Messages>

          <TicketFooter
            disabled={ticket.status === 2 || message === ''}
            file={hasFile}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '16px',
              }}
            >
              <Icons>
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  icon={faSmile}
                  onClick={() => setShowEmokiPicker(!showEmokiPicker)}
                />
              </Icons>
              <CustomTextArea
                block
                onKeyPress={handleUserKeyPress}
                style={{ resize: 'none' }}
                height="140px"
                name="message"
                background
                disabled={ticket.status === 2}
                placeholder="Digite sua mensagem aqui..."
                value={message}
                onChange={e => {
                  if (e.target.value.length === 1) {
                    WebSocketInstance.send(
                      JSON.stringify({
                        type: 'typing',
                        data: {
                          name: user.full_name,
                          id: user.id,
                          typing: true,
                        },
                      }),
                    );
                  } else if (e.target.value.length === 0) {
                    WebSocketInstance.send(
                      JSON.stringify({
                        type: 'typing',
                        data: {
                          name: user.full_name,
                          id: user.id,
                          typing: false,
                        },
                      }),
                    );
                  }
                  setMessage(e.target.value);
                  checkForShortcut(e.target.value);
                }}
              />
              <StyledDropzone
                acceptedFiles={acceptedFiles}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
              />
            </div>
            <Button
              disabled={
                loading ||
                ticket.status === 2 ||
                ((!message || message === '') && acceptedFiles.length === 0) ||
                (ticket.can_ask_help && ticket.asked_help)
              }
              width="130px"
              onClick={async e => {
                setLoading(true);
                await sendMessage(e);
                setLoading(false);
              }}
            >
              Enviar
              <FontAwesomeIcon
                icon={loading ? faSpinner : faPaperPlane}
                spin={loading}
              />
            </Button>
          </TicketFooter>
          {showEmokiPicker && (
            <div
              style={{
                position: 'absolute',
                zIndex: 2,
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                }}
                onClick={() => setShowEmokiPicker(false)}
              />
              <Picker
                onSelect={emoji => {
                  setMessage(`${message}${emoji.native}`);
                }}
                set="facebook"
                theme={theme.darkMode ? 'dark' : 'light'}
                i18n={{
                  search: 'Procurar',
                  categories: {
                    search: 'Resultados da busca',
                    recent: 'Usados recentemente',
                    people: 'Carinhas & Pessoas',
                    nature: 'Animais & Natureza',
                    foods: 'Comidas & Bebidas',
                    activity: 'Atividade',
                    places: 'Viagens & Lugares',
                    objects: 'Objetos',
                    symbols: 'Símbolos',
                    flags: 'Bandeiras',
                    custom: 'Customizado',
                  },
                }}
                style={{ position: 'absolute', left: '40px', bottom: '230px' }}
              />
            </div>
          )}
        </TicketWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: '20px',
          }}
        >
          <Button
            error
            outline
            onClick={() => {
              if (user.is_staff) {
                if (ticket.status !== 2) {
                  setNow(true);
                  setShowIssueModal(true);
                } else closeIn7Days(null, null, true);
              } else if (ticket.status !== 2) {
                setNow(true);
                setRatingModal(true);
              } else closeIn7Days(null, null, true);
            }}
          >
            {ticket.status !== 2 ? 'Fechar ticket' : 'Reabrir ticket'}
          </Button>
        </div>
        {user.is_staff && (
          <StaffInfo
            ticket={ticket}
            target={target}
            // setTicket={updateTicket}
            user={_user}
            clouds={clouds}
          />
        )}
      </>
    )
  );
};

export default (props: any) => (
  <TicketDetailProvider>
    <TicketDetail {...props} />
  </TicketDetailProvider>
);
