import styled, { css } from 'styled-components';

export const Selection = styled.div`
  height: 160px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

interface ISelectProps {
  selected: boolean;
}

export const Select = styled.div<ISelectProps>`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: 1px solid transparent;
  margin: 0px 8px;
  background: ${props => props.theme.interface2};
  padding: 30px;

  &:hover {
    border: 2px solid ${props => props.theme.brand_primary_color};
    box-shadow: 0px 3px 15px #64728c4d;
  }

  img {
    height: 100%;
    width: 100%;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  ${props =>
    props.selected &&
    css`
      box-shadow: 0px 3px 15px #64728c4d;
      border: 2px solid ${props => props.theme.brand_primary_color};
    `}
`;
