import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faSpinner } from '@fortawesome/free-solid-svg-icons';

import Modal from 'components/Modal';
import { Field, Label, TextArea } from 'components/Input';
import { Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import like from 'assets/img/client-like.png';
import dislike from 'assets/img/client-dislike.png';
import { Selection, Select } from './styles';

interface IRatingModalProps {
  setShow: (value: boolean) => void;
  show: boolean;
  action: (rating: number, ratingComment: any) => void;
  rating: number;
  ticket: any;
}

const RatingModal = ({
  action,
  show,
  setShow,
  ticket,
  rating: _rating,
}: IRatingModalProps) => {
  const [loading, setLoading] = useState(false);

  const [rating, setRating] = useState(ticket?.rating || _rating);
  const [ratingComment, setRatingComment] = useState(ticket?.rating_comment);

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faStar} />
        </Icon>
        <Text>
          <Title>Avalie o atendimento</Title>
          <Subtitle>Como foi sua experiência nesse ticket?</Subtitle>
        </Text>
      </Header>

      <Content>
        <Row>
          <Col>
            <Label>Você foi bem atendido?</Label>
            <Selection>
              <Select selected={rating === 1} onClick={() => setRating(1)}>
                <img src={like} alt="like" style={{ height: 'auto' }} />
              </Select>
              <Select selected={rating === 2} onClick={() => setRating(2)}>
                <img alt="dislike" src={dislike} style={{ height: 'auto' }} />
              </Select>
            </Selection>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field>
              <Label>Sua solicitação foi resolvida? Deixe um comentário</Label>
              <TextArea
                block
                height="100px"
                value={ratingComment}
                onChange={e => setRatingComment(e.target.value)}
              />
            </Field>
          </Col>
        </Row>
      </Content>
      <Footer>
        <Button
          icon
          disabled={!rating}
          onClick={() => {
            setLoading(true);
            setShow(false);
            action(rating, ratingComment);
          }}
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            'Fechar ticket'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default withTheme(RatingModal as any);
